
import { defineComponent, reactive, ref } from "vue";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import ForwardHeader from "@/components/Headers/ForwardHeader.vue";
import { IonSlides, IonSlide } from "@ionic/vue";
import Form4 from "@/views/Onboarding/Forms/Form4.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Report",
  components: { Form4, ForwardHeader, DefaultLayout, IonSlides, IonSlide },
  setup() {
    const slider = ref(null);
    const currentIndex = ref<number>(0);
    const router = useRouter();

    const onNext = (value: number) => {
      currentIndex.value = value;
      //@ts-ignore
      slider.value.$el.slideTo(value);
    };

    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      noSwiping: true,
      noSwipingClass: "swiper-no-swiping",
      autoHeight: true,
    };

    const data = reactive({
      licenseClass: null,
      theoryCourse: null,
      branch: null,
    });

    const onSubmit = () => {
      router.push({ name: "ReportDone" });
    };

    return {
      onNext,
      slideOpts,
      data,
      slider,
      onSubmit,
    };
  },
});
